/* h1 {
  font-size: 60px;
  text-align: center;
  padding: 2rem;
  margin-top: 20px;
} */
.timeline {
  position: relative;
  margin: 0px auto;
  padding: 20px 0;
  width: 1000px;
  box-sizing: border-box;
}
.timeline:before {
  content: '';
  position: absolute;
  left: 50%;
  width: 3px;
  height: 100%;
  top: -20px;
  background: #c5c5c5;
}
.timeline ul {
  padding: 0;
  margin: 0;
}
.timeline ul li {
  list-style: none;
  position: relative;
  width: 50%;
  padding: 20px 40px;
  box-sizing: border-box;
}
.timeline ul li:nth-child(odd) {
  float: left;
  text-align: right;
  clear: both;
}
.timeline ul li:nth-child(even) {
  float: right;
  text-align: left;
  clear: both;
}
.content {
  padding-bottom: 20px;
  transform: translateY(-4px);
}
.timeline ul li:nth-child(odd):before {
  content: '';
  position: absolute;
  width: 11px;
  height: 11px;
  top: 24px;
  right: -7px;
  background: #48d7d4;
  border-radius: 50%;
  box-shadow: 0 0 0 3px #c2fdfe;
}
.timeline ul li:nth-child(even):before {
  content: '';
  position: absolute;
  width: 11px;
  height: 11px;
  top: 24px;
  left: -4px;
  background: #48d7d4;
  border-radius: 50%;
  box-shadow: 0 0 0 3px #c2fdfe;
}
.timeline ul li h3 {
  padding: 0;
  margin: 0;
  color: #0ac5a8;
  font-weight: 600;
}
.timeline ul li p {
  margin: 10px 0 0;
  padding: 0;
}
.timeline ul li .time h4 {
  margin: 0;
  padding: 0;
  font-size: 14px;
}
.timeline ul li:nth-child(odd) .time {
  position: absolute;
  top: 12px;
  right: -155px;
  margin: 0;
  padding: 8px 16px;
  background: #48d7d4;
  color: #fff;
  border-radius: 18px;
  box-shadow: 0 0 0 3px #c2fdfe;
}
.timeline ul li:nth-child(even) .time {
  position: absolute;
  top: 12px;
  left: -160px;
  margin: 0;
  padding: 8px 16px;
  background: #48d7d4;
  color: #fff;
  border-radius: 18px;
  box-shadow: 0 0 0 3px #c2fdfe;
}
@media (max-width: 1000px) {
  .timeline {
    width: 100%;
  }
}
@media (max-width: 767px) {
  .timeline {
    width: 100%;
    padding-bottom: 0;
  }
  h1 {
    font-size: 40px;
    text-align: center;
  }
  .timeline:before {
    left: 20px;
    height: 100%;
  }
  .timeline ul li:nth-child(odd),
  .timeline ul li:nth-child(even) {
    width: 100%;
    text-align: left;
    padding-left: 50px;
    padding-bottom: 50px;
  }
  .timeline ul li:nth-child(odd):before,
  .timeline ul li:nth-child(even):before {
    top: -18px;
    left: 16px;
  }
  .timeline ul li:nth-child(odd) .time,
  .timeline ul li:nth-child(even) .time {
    top: -30px;
    left: 50px;
    right: inherit;
  }
}

/* .fade {
  animation: fadeIn ease 2s;
  -webkit-animation: fadeIn ease 2s;
  -moz-animation: fadeIn ease 2s;
  -o-animation: fadeIn ease 2s;
  -ms-animation: fadeIn ease 2s;
} */

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
/* 
.btn-shine {
  color: #ffffff;
  background: linear-gradient(to right, #000000 0, #48d7d4 10%, #000000 20%);
  background-position: 0;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  animation: shine 3s infinite linear;
  animation-fill-mode: forwards;
  -webkit-text-size-adjust: none;
  font-weight: 600;
  font-size: 16px;
  text-decoration: none;
  white-space: nowrap;
}
@keyframes shine {
  0% {
    background-position: 0;
  }
  60% {
    background-position: 180px;
  }
  100% {
    background-position: 180px;
  }
} */

.btn-shine {
  display: block;

  animation: show 0.01s forwards, pop-word 3.5s forwards;
  animation-timing-function: cubic-bezier(0.14, 1.23, 0.33, 1.16);
  opacity: 0;

  transform: rotateX(120deg);
  transform-origin: 50% 100%;
}

@keyframes pop-word {
  to {
    transform: rotateX(0);
  }
}

@keyframes show {
  to {
    opacity: 1;
  }
}

@keyframes bar-scale {
  to {
    transform: scaleY(1);
  }
}
