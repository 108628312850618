:root {
  font-family: 'Exo 2', sans-serif;
  line-height: 1.5;
  font-weight: 400;
  color-scheme: light dark;
  font-synthesis: none;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-text-size-adjust: 100%;
}

body {
  font-family: 'Exo 2', sans-serif;
  font-weight: 400;
  line-height: 1.5;
  margin: 0;
}
*::-webkit-scrollbar {
  width: 0 !important;
  height: 0 !important;
  font-family: 'Exo 2', sans-serif;
}

h1 {
  font-family: 'Orbitron', sans-serif;
  font-size: 3.2em;
  line-height: 1.1;
}

.subtext {
  font-family: 'Michroma', sans-serif;
}

.sticky {
  position: fixed;
  top: 0;
  width: 100vw;
  z-index: 1000;
}

.floating {
  animation-name: floating;
  animation-duration: 3s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
  margin-left: 30px;
  margin-top: 5px;
}

.App {
  min-height: 100vh;
  text-align: center;
}

.MainContent {
  justify-content: center;
  position: relative;
  padding-top: 3.9rem;
}

@keyframes floating {
  0% {
    transform: translate(0, 0px);
  }
  50% {
    transform: translate(0, 15px);
  }
  100% {
    transform: translate(0, -0px);
  }
}

@font-face {
  font-family: 'Gaoel';
  src: local('Gaoel'), url('./assets/fonts/Gaoel.ttf') format('truetype');
}
